import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-screen h-screen flex fixed top-0 left-0 transform bg-body bg-opacity-70 z-10 overflow-y-auto" }
const _hoisted_2 = { class: "w-full max-w-xl sm-down:max-w-11/12 m-auto bg-white px-8 pb-8 rounded-lg shadow-xl relative" }
const _hoisted_3 = { class: "text-2xl text-center mt-8" }
const _hoisted_4 = { class: "flex space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.message), 1),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("button", {
          class: "flex-1 p-3 text-primary bg-white border border-primary rounded hover:bg-primary hover:bg-opacity-5 transition",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancelClick && _ctx.onCancelClick(...args)))
        }, _toDisplayString(_ctx.$t('Basic.Cancel')), 513), [
          [_vShow, _ctx.showCancelBtn]
        ]),
        _withDirectives(_createElementVNode("button", {
          class: "flex-1 p-3 text-white bg-primary rounded hover:opacity-90 transition",
          type: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onOkClick && _ctx.onOkClick(...args)))
        }, " OK ", 512), [
          [_vShow, _ctx.showOkBtn]
        ])
      ])
    ])
  ], 512)), [
    [_vShow, _ctx.showAlert]
  ])
}
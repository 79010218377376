import api from "@/services/api_request";
import {
    DeleteOrderOutput,
    FareFamilyOutput,
    OfferAvailabilityInput,
    OfferAvailabilityOutput, VoidOrderOutput
} from "@/models/offer_models";
import { LocalDb } from "@/repository/localdb";
import type { OrderRequest, OrderResponse, ReplanishResponse } from "@/models/order_modes";

export const OfferService = {
    makeOrder: async (request: OrderRequest): Promise<OrderResponse> => {
        const tokenData = LocalDb.getToken();
        const data = await api.post('/flight/createorder', request, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        return data;
    },
    voidOrder: async (request: number | string): Promise<VoidOrderOutput> => {
        const tokenData = LocalDb.getToken();
        const data = await api.update('/flight/voidorder?orderId=' + request, "", { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        return data;
    },
    deleteOrder: async (request: number): Promise<DeleteOrderOutput> => {
        const tokenData = LocalDb.getToken();
        const data = await api.delete('/flight/deleteorder?orderId=' + request, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        return data;
    },
    deleteRailwayOrder: async (request: string): Promise<DeleteOrderOutput> => {
        const tokenData = LocalDb.getToken();
        const data = await api.delete('/railway/delete-order?orderId=' + request, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        return data;
    },
    getOfferAvailability: async (request: OfferAvailabilityInput): Promise<OfferAvailabilityOutput> => {
        const tokenData = LocalDb.getToken();
        const data = await api.post('/flight/getofferavailability', request, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        return data;
    },
    getFareFamily: async (buyId: string): Promise<FareFamilyOutput> => {
        const tokenData = LocalDb.getToken();
        const session = LocalDb.getSession();
        const request = { buyId: buyId, session: session };
        const data = await api.post('/flight/getfarefamily', request, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        return data;
    },
    replanishBalance: async (replanishAmount: number): Promise<ReplanishResponse> => {
        const tokenData = LocalDb.getToken();
        const request = {
            amount: replanishAmount
        }
        const { data } = await api.post(`/Users/replenish_balance?amount=${replanishAmount}`, request, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
        return data
    }
};

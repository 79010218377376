
import { defineComponent, toRaw } from "vue";
import {
  RegisterRequest,
  SendMeCodeRequest,
  VerifyMeRequest,
} from "@/models/auth/auth_models";
import { AuthService } from "@/services/auth_service";
import { PhoneNumber, PhoneNumberUtil } from "google-libphonenumber";
import { UserService } from "@/services/user_service";
import { LocalDb } from "@/repository/localdb";
import Swal from "sweetalert2";
import { AvioneMessage } from "@/constants/error_messages";
import { ResponseStatusType } from "@/constants/response_status_type";
import Datepicker from "vue3-datepicker";
import { uz } from "date-fns/locale";
import { CollectionResponse, DocumentType } from "@/models/collection_models";
import { Country, Gender } from "@/models/auth/user_models";
import { isStringNullOrEmpty } from "@/helpers/general_helper";
import { getCountryInfoByPhoneNumber } from "@/helpers/phone_number_helper";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "LoginForm",
  components: {
    Datepicker,
  },
  props: ["isOpen"],
  data() {
    return {
      smsCode: "",
      phoneNumber: "",
      loading: false,
      showPhoneNumber: true,
      showSmsVerify: false,
      showRegistration: false,

      documentTypes: {} as DocumentType[],
      genders: {} as Gender[],
      countries: {} as Country[],
      loginModel: {} as SendMeCodeRequest,
      verifyMeModel: {} as VerifyMeRequest,
      registrationModel: {
        lastName: `${Math.floor(Math.random() * 10000) + 1}`,
        firstName: "Пользователь",
        middleName: `${Math.floor(Math.random() * 10000) + 1}`,
        dateOfBirth: "2000-01-31T19:00:00.000Z",
        gender: {
            "code": "M",
            "name": "Мужчина",
            "isDefault": false,
            "orderNumber": 1
        },
      } as RegisterRequest,
    };
  },
  computed: {
    locale: () => uz,
  },
  watch: {
    showRegistration: function (before, after) {
      if (this.showRegistration) {
        const collections: CollectionResponse | null = LocalDb.getCollections();
        if (collections) {
          this.documentTypes = collections.documentTypes;
          this.genders = collections.genders;
          this.countries = collections.countries;
        }
      }
    },
  },
  methods: {
    onCloseForm: function () {
      this.smsCode = "";
      this.showPhoneNumber = true;
      this.showSmsVerify = false;
      this.showRegistration = false;
      this.$emit("onLoginFormCloseEvent");
    },
    onRegisterUser: async function (event: any) {
      try {
        if (event) event.preventDefault();

        if (
          isStringNullOrEmpty(this.loginModel.phoneNumber) ||
          isStringNullOrEmpty(this.smsCode) ||
          isStringNullOrEmpty(this.registrationModel.firstName) ||
          isStringNullOrEmpty(this.registrationModel.lastName) ||
          isStringNullOrEmpty(this.registrationModel.middleName) ||
          isStringNullOrEmpty(this.registrationModel.dateOfBirth) ||
          !this.registrationModel.gender
        )
          throw new Error(this.$t(AvioneMessage.ALL_FIELDS_REQUIRED));

        this.registrationModel.phoneNumber = this.loginModel.phoneNumber ?? "";
        this.registrationModel.phoneNumberInfo = getCountryInfoByPhoneNumber(
          this.phoneNumber, this
        );
        this.registrationModel.sMSCode = this.smsCode;

        this.loading = true;
        await AuthService.register(this.registrationModel).then(
          async (response) => {
            if (response.status == ResponseStatusType.Ok) {
              await this.onVerifyMe();
            }
          }
        );
      } catch (e) {
        await Swal.fire("", e.message, "warning");
      }
    },
    onSendMeCode: async function (event: any) {
      try {
        if (event) event.preventDefault();

        const phoneNumber = this.getPhoneNumber(this.phoneNumber);
        if (!phoneNumber)
          throw new Error(this.$t(AvioneMessage.PHONE_NUMBER_FORMAT_NOT_FOUND));

        this.loginModel = {
          phoneNumberCode: phoneNumber.getCountryCode()?.toString(),
          phoneNumber: phoneNumber.getNationalNumber()?.toString(),
          userVerificationType: 1,
        };

        this.loading = true;
        await AuthService.sendMeCode(this.loginModel).then((response) => {
          if (response.status == 0) {
            this.loading = false;
            this.showSmsVerify = true;
            this.showPhoneNumber = false;
          }
        });
      } catch (e) {
        await Swal.fire("", this.$t(e.message), "warning");
      }
    },
    onVerifyMe: async function (event: any = null) {
      try {
        if (event) event.preventDefault();

        if (this.smsCode.length != 4)
          throw new Error(this.$t(AvioneMessage.SMS_CODE_NOT_CORRECT));

        if (this.loginModel) {
          this.verifyMeModel = {
            phoneNumberCode: this.loginModel.phoneNumberCode,
            phoneNumber: this.loginModel.phoneNumber,
            smsCode: this.smsCode,
            userVerificationType: this.loginModel.userVerificationType,
            token: "",
          };

          this.loading = true;
          await AuthService.verifyMe(this.verifyMeModel).then(
            async (response) => {
              if (response.status == ResponseStatusType.Ok) {
                if (response.data.verified) {
                  if (
                    response.data.token &&
                    !isStringNullOrEmpty(response.data.token.token)
                  ) {
                    LocalDb.saveToken(response.data.token);
                    LocalDb.saveCurrentUser(response.data.user);
                    await UserService.getStoredData().then((storedData) => {
                      this.loading = false;
                      LocalDb.saveMyFlightOrders(storedData.orders);
                      LocalDb.saveFlightPassengers(storedData.passengers);
                      this.$emit("onUserLoggedInEvent");
                    });
                  } else {
                    
                    
                    // ======== REGISTRATION OPEN =======

                    this.registrationModel.phoneNumber = this.loginModel.phoneNumber ?? "";
                    this.registrationModel.phoneNumberInfo = getCountryInfoByPhoneNumber(
                      this.phoneNumber, this
                    );

                    this.registrationModel.sMSCode = this.smsCode;

                    await AuthService.register(this.registrationModel).then(
                      async (response) => {
                        if (response.status == ResponseStatusType.Ok) {
                          await this.onVerifyMe();
                          this.showSmsVerify = false;
                          this.loading = false;
                        }
                      }
                    );


                    // this.showRegistration = true;
                  }
                } else if (!response.data.verified) {
                  this.loading = false;
                  await Swal.fire(
                    "",
                    this.$t(AvioneMessage.WRONG_SMS_CODE),
                    "warning"
                  );
                }
              } else {
                this.loading = false;
                await Swal.fire("", response.message, "warning");
              }
            }
          );
        }
      } catch (e) {
        await Swal.fire("", e.message, "error");
      }
    },
    getPhoneNumber: function (phoneNumber: string): PhoneNumber | undefined {
      try {
        const _phoneNumber = this.cleanUpPhoneNumber(phoneNumber);
        const phoneUtil = PhoneNumberUtil.getInstance();
        const number = phoneUtil.parse(_phoneNumber);

        if (!phoneUtil.isValidNumber(number))
          throw new Error(this.$t(AvioneMessage.PHONE_NUMBER_FORMAT_NOT_FOUND));

        return number;
      } catch (e) {
        Swal.fire(
          "",
          this.$t(AvioneMessage.PHONE_NUMBER_FORMAT_NOT_FOUND),
          "error"
        );
      }
    },
    cleanUpPhoneNumber: function (number: string): string {
      number = number.trim().replace("+", "");
      number = "+".concat(number);
      return number;
    },
  },
});

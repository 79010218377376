import api from './api_request';
import { IATAOutput } from "@/models/search_parameters";

export const LocationService = {
    findLocation: async (request: string): Promise<IATAOutput> => {
        const data = await api.get('/location/getairportCities?locale=RU&filter=' + request)
            .then(response => {
                return response;
            })
        return data;
    },

    findByCode: async (code: string): Promise<IATAOutput> => {
        return await api.get('/location/getcityloc?locale=RU&filter=' + code)
            .then(response => {
                return response;
            })
    },
    findLocationRailway: async (requestrail:string) => {
        const data = await api.get('/railway/stations?stationName=' + requestrail)
            .then((res)=>{
                return res
            })
        return data
    }
}

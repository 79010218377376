import {ref} from "vue";


export function detectData(){
    const detect = ref(false)
    const doOrder = ref({})
    return{
        detect,
        doOrder
    }
}
export const DetectData = detectData()

import { OrderData } from "@/models/stored_data_models";
import { Passenger, TicketBooking } from "@/models/order_modes";
import { Token, User } from "@/models/auth/user_models";
import { SearchParameter } from "@/models/search_parameters";
import { CollectionResponse } from "@/models/collection_models";
import { AvioneMessage } from "@/constants/error_messages";

const BOOKING_TICKET_KEY = "bookingTicket";
const TOKEN_KEY = 'token';
const SESSION_KEY = 'session';
const USER_KEY = 'currentUser';
const MY_ORDERS_KEY = 'myOrders';
const MY_PASSENGERS_KEY = 'myPassengers';
const SEARCH_PARAMS_KEY = 'searchParams';
const MY_COLLECTION_KEY = 'collections';

export const LocalDb = {
    removeAll: () => {
        localStorage.removeItem(BOOKING_TICKET_KEY);
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(SESSION_KEY);
        localStorage.removeItem(USER_KEY);
        localStorage.removeItem(MY_ORDERS_KEY);
        localStorage.removeItem(MY_PASSENGERS_KEY);
        localStorage.removeItem(SEARCH_PARAMS_KEY);
        localStorage.removeItem(MY_COLLECTION_KEY);
    },
    saveSearchParameters: (vale: SearchParameter) => {
        localStorage.setItem(SEARCH_PARAMS_KEY, JSON.stringify(vale));
    },
    getSearchParameters: (): SearchParameter | null => {
        const value = localStorage.getItem(SEARCH_PARAMS_KEY);
        if (value) {
            const valueObj: SearchParameter = JSON.parse(value);
            return valueObj;
        }
        return null;
    },

    isUserLoggedIn: (): boolean => {
        const user: User | null = LocalDb.getCurrentUser();
        return user != null;
    },
    getCurrentUser: (): User | null => {
        const userData = localStorage.getItem(USER_KEY);
        if (userData) {
            const userDataObj: User = JSON.parse(userData);
            return userDataObj;
        }
        return null;
    },
    saveCurrentUser: (user: User) => {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
    },
    saveMyFlightOrder: (order: OrderData) => {
        const _orders: OrderData[] = LocalDb.getMyFlightOrders() ?? [];
        _orders.push(order);
        localStorage.setItem(MY_ORDERS_KEY, JSON.stringify(_orders));
    },
    getMyFlightOrder: (pnrNumber: string): OrderData | undefined => {
        const _orders = LocalDb.getMyFlightOrders();
        if (_orders) {
            const result: OrderData | undefined = _orders.find((s) => s.pnrNumber === pnrNumber);
            return result;
        }
        return undefined;
    },
    getMyRailwayOrder: (orderId: string): any => {
        const _orders = LocalDb.getMyFlightOrders();
        if (_orders) {
            const result = _orders.find((s) => s.orderId?.toString() === orderId);
            return result;
        }
        return undefined;
    },
    deleteMyFlightOrder: (orderId: number) => {
        const _orders = LocalDb.getMyFlightOrders();
        if (_orders) {
            _orders.forEach((item, index) => {
                if (item.orderId == orderId)
                    _orders.splice(index, 1);
            })
            LocalDb.saveMyFlightOrders(_orders);
        }
    },
    deleteMyRailwayOrder: (_orderId: string) => {
        let _orders = LocalDb.getMyFlightOrders();
        if (_orders) {
            _orders = _orders.filter(order => order.orderId !== _orderId)
            LocalDb.saveMyFlightOrders(_orders);
        }
    },
    saveMyFlightOrders: (orders: OrderData[]) => {
        localStorage.setItem(MY_ORDERS_KEY, JSON.stringify(orders));
    },
    getMyFlightOrders: (): OrderData[] | null => {
        const data = localStorage.getItem(MY_ORDERS_KEY);
        if (data) {
            const result: OrderData[] = JSON.parse(data);
            return result;
        }
        return null;
    },
    saveFlightPassengers: (passengers: Passenger[]) => {
        localStorage.setItem(MY_PASSENGERS_KEY, JSON.stringify(passengers));
    },
    getFlightPassengers: (): Passenger[] | null => {
        const data = localStorage.getItem(MY_PASSENGERS_KEY);
        if (data) {
            const result: Passenger[] = JSON.parse(data);
            return result;
        }
        return null;
    },
    saveBookingTicket: (ticketBooking: TicketBooking) => {
        localStorage.setItem(BOOKING_TICKET_KEY, JSON.stringify(ticketBooking));
    },
    getBookingTicket: (): TicketBooking | null => {
        const data = localStorage.getItem(BOOKING_TICKET_KEY);
        if (data) {
            const result: TicketBooking = JSON.parse(data);
            return result;
        }
        return null;
    },
    removeBookingTicket: () => {
        localStorage.removeItem(BOOKING_TICKET_KEY);
    },
    getToken: (): Token | null => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            const tokenObj: Token = JSON.parse(token);
            return tokenObj;
        }
        return null;
    },
    saveToken: (token: Token) => {
        localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
    },
    removeToken: () => {
        localStorage.removeItem(TOKEN_KEY);
    },
    saveSession: (session: string) => {
        localStorage.setItem(SESSION_KEY, JSON.stringify(session));
    },
    getSession: (): string | "" => {
        const session = localStorage.getItem(SESSION_KEY);
        if (session) {
            const sessionObj: string = JSON.parse(session);
            return sessionObj;
        }
        return "";
    },
    saveCollections: (collections: CollectionResponse) => {
        localStorage.setItem(MY_COLLECTION_KEY, JSON.stringify(collections));
    },
    getCollections: (): CollectionResponse | null => {
        const data = localStorage.getItem(MY_COLLECTION_KEY);
        if (data) {
            const result: CollectionResponse = JSON.parse(data);
            return result;
        }
        return null;
    },
};

export enum FlightClass {
    Economy = 'E',  
    Business = 'B',
}

export enum PassengerType {
    Adult,
    Kid,
    Baby,
}

<template>
  <div class="bg-body-light content-wrapper">
    <div class="container mx-auto mb-10 sm:mb-14">
      <div class="content">
        <p>
          {{ $t('pages.home.leadText') }}
        </p>
        <div class="flex flex-wrap -mx-2 sm:-mx-4 mb-2">
          <div class="w-full md:w-4/12">
            <img
              src="../assets/img/airplane.jpg"
              class="rounded article-img"
              :alt="$t('pages.home.shortTitle')"
            >
          </div>
          <div
            class="w-full md:w-8/12"
            v-html="$t('pages.home.content')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.content-wrapper {
  padding: 4rem 0 1rem;
}
.content {
  font-size: 16px;
  margin: 0 auto;
  background-color: #fff;
  padding: 3rem 6rem;
  border-radius: 1rem;
}
h2 {
  margin: 1rem 0;
}
ul {
  padding-left: 2rem;
  margin: 1rem;
  list-style: disc;
}
p {
  margin: .25rem 0;
}
.article-img {
  max-width: 300px;
  width: 100%;
  margin: 2rem 4rem 2rem 2rem;
}

@media (max-width: 1280px) {
  .article-img {
    max-width: 230px;
  }
}
@media (max-width: 1024px) {
  .article-img {
    max-width: 150px;
  }
}
@media (max-width: 768px) {
  .article-img {
    max-width: 90%;
    margin: 1rem;
  }
  .content-wrapper {
    padding: 2rem .5rem;
  }
  .content {
    padding: 2rem;
  }
}

</style>
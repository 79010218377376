import api from "@/services/api_request";
import { LocalDb } from "@/repository/localdb";
import { ref } from 'vue'
import { DetectData } from "@/constants/detect";
const { detect } = DetectData
const searchForm = ref({
    DetailNumPlaces: 1,
    stationFrom: '',
    stationTo: '',
    direction: [
        {
            depDate: '',
            fullDay: true,
            type: "Forward"
        }
    ]
})


const searchRes = ref([])
const searchResPassRoute = ref([])
export function searchService() {

    const tokenData = LocalDb.getToken();
    const saveSearch = async () => {
        detect.value = true


        const response = await api.post('/railway/search/', searchForm.value, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then((res) => {
                detect.value = false
                for (let i = 0; i < res.stations.express.direction.length; i++) {
                    searchResPassRoute.value = res.stations.express.direction[i].passRoute
                    for (let j = 0; j < res.stations.express.direction[i].trains.length; j++) {
                        searchRes.value = res.stations.express.direction[i].trains[j].train
                    }
                }
            })
    }

    const SearchByDay = async (date: any) => {
        detect.value = true
        searchForm.value.direction[0].depDate = date
        const response = await api.post('/railway/search/', searchForm.value, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then((res) => {
                detect.value = false
                for (let i = 0; i < res.stations.express.direction.length; i++) {
                    searchResPassRoute.value = res.stations.express.direction[i].passRoute
                    for (let j = 0; j < res.stations.express.direction[i].trains.length; j++) {
                        searchRes.value = res.stations.express.direction[i].trains[j].train
                    }
                }
            })
    }
    return {
        SearchByDay,
        saveSearch,
        searchForm,
        searchRes,
        searchResPassRoute
    }
}
export const searchApi = searchService()

import api from "@/services/api_request";

import { StoredDataResponse, passengersDataResponse, orderDataResponse, UserBalanceData } from "@/models/stored_data_models";
import { LocalDb } from "@/repository/localdb";

export const UserService = {
    getStoredData: async (): Promise<StoredDataResponse> => {
        const tokenData = LocalDb.getToken();
        const data = await api.get('/flight/getstoreddata', { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        return data;
    },
    getOrdersWithPagable: async (pagable: any,): Promise<orderDataResponse> => {
        const tokenData = LocalDb.getToken();
        const data = await api.get('/flight/getorders', { params: pagable, headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        return data;
    },
    getPassengerWithPagable: async (pagable: any): Promise<passengersDataResponse> => {
        const tokenData = LocalDb.getToken();
        const data = await api.get('/flight/getpassengers', { params: pagable, headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        return data;
    },
    getUserBalance: async (): Promise<UserBalanceData> => {
        const tokenData = LocalDb.getToken();
        const data = await api.get('/Users/balance', { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
        return data;
    },
};
// getStoreDataWithPagable

<template>
  <div>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path :style="$route.name === 'railway' || $route.name === 'railwaySearchResults' ? 'fill:#0e75fc' : 'fill:#636262'" d="M11.9579 14.3158L13.4737 15.5789V16H1.68421V15.5789L3.2 14.3158H1.68421C1.23753 14.3158 0.809144 14.1383 0.493294 13.8225C0.177443 13.5066 0 13.0783 0 12.6316V3.36842C0 2.47506 0.354886 1.61829 0.986588 0.986588C1.61829 0.354886 2.47506 0 3.36842 0H11.7895C12.6828 0 13.5396 0.354886 14.1713 0.986588C14.803 1.61829 15.1579 2.47506 15.1579 3.36842V12.6316C15.1579 13.0783 14.9805 13.5066 14.6646 13.8225C14.3488 14.1383 13.9204 14.3158 13.4737 14.3158H11.9579ZM1.68421 3.36842V6.73684H13.4737V3.36842H1.68421ZM7.57895 12.6316C8.02563 12.6316 8.45401 12.4541 8.76986 12.1383C9.08571 11.8224 9.26316 11.394 9.26316 10.9474C9.26316 10.5007 9.08571 10.0723 8.76986 9.75645C8.45401 9.4406 8.02563 9.26316 7.57895 9.26316C7.13227 9.26316 6.70388 9.4406 6.38803 9.75645C6.07218 10.0723 5.89474 10.5007 5.89474 10.9474C5.89474 11.394 6.07218 11.8224 6.38803 12.1383C6.70388 12.4541 7.13227 12.6316 7.57895 12.6316Z" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "poyezdIndex"
}
</script>

<style scoped>
h{
  color: #a0a0a0;
}
</style>

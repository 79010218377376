import {FlightClass, PassengerTypeQty} from "@/models/collection_models";


export class SearchParameter {
    isDirectFlight: boolean;
    directions: Direction[];
    selectedPassengers: PassengerTypeQty[];
    selectedClasses: FlightClass | null;

    constructor() {
        this.isDirectFlight = false;
        this.selectedClasses = null;
        this.selectedPassengers = [];
        this.directions = [];
    }
}

export class Direction {
    index: number;
    selectedFromDate: string;
    selectedToDate: string;
    selectedFromLocation: IATALocation | null;
    selectedToLocation: IATALocation | null;

    constructor(index: number) {
        this.index = index;
        this.selectedFromDate = "";
        this.selectedToDate = "";
        this.selectedFromLocation = null;
        this.selectedToLocation = null;
    }
}


export interface Airport {
    id: number;
    code: string;
    name: string;
    locale: string;
}

export interface City {
    code: string;
    name: string;
    locale: string;
    airports: Airport[];
}

export interface IATALocation {
    city: City;
    airport: Airport;
}
export interface Train {
    code: string;
    name: string;
    locale: string;
    airports: Airport[];
}
export interface IATALocationr {
    city: Train;
}

export interface IATAOutput {
    cities: City[];
    airports: Airport[];
}

import api from './api_request';
import {
    AviaCompany,
    FlightResultOutput,
    FlightSearchResult,
    Offer,
    Segment,
    Ticket,
    TicketPrice
} from '@/models/flight_models';
import { LocalDb } from "@/repository/localdb";
export const FlightSearchService = {
    search: async (request: string): Promise<FlightSearchResult> => {
        const tokenData = LocalDb.getToken();

        const data = await api.get('/flight/searchTickets?' + request, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        const result: FlightSearchResult = FlightSearchService.convertSearchResultToTicket(data);

        return result;
    },
    searchContinue: async (requestId: string, request: string,): Promise<FlightSearchResult> => {
        const tokenData = LocalDb.getToken();
        const data = await api.get('/flight/searchTicketsContinue?requestId=' + requestId + '&' + request, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        const result: FlightSearchResult = FlightSearchService.convertSearchResultToTicket(data);

        return result;
    },

    searchCharter: async (): Promise<FlightSearchResult> => {
        const data = await api.get('/Flight/searchcharter')
            .then(response => {
                return response;
            })
        return FlightSearchService.convertSearchResultToTicket(data);
    },

    convertSearchResultToTicket: (value: FlightResultOutput): FlightSearchResult => {
        const tickets: Ticket[] = [];
        const companies: AviaCompany[] = [];
        const ticketPrices: TicketPrice[] = [];
        let maxPrice = 0;

        for (const carrier of value.carriers) {
            for (const index in carrier.offers) {
                const offer: Offer = carrier.offers[index];
                if (offer.segments) {
                    const departures = offer.segments.filter((element) => element.buyId == "");
                    const arrivals = offer.segments.filter((element) => element.buyId != "");
                    if (departures.length > 0) {
                        for (const arrival of arrivals) {
                            const ticket: Ticket = { departure: [] };
                            let durationMinutes = 0;
                            ticket.arrival = FlightSearchService.convertArrivalSegmentToSegmentModel(arrival);
                            ticket.departure = FlightSearchService.convertDepartureSegmentsToSegmentModels(departures);
                            ticket.carrierCode = carrier.carrierCode;
                            ticket.carrierLogo = carrier.carrierLogo;
                            ticket.carrierName = carrier.carrierName;
                            ticket.minPrice = carrier.minPrice;
                            ticket.departure?.forEach((element: Segment) => {

                                element.flightsInfo.forEach((flightInfoModel) => {
                                    durationMinutes += flightInfoModel.durationMinutes ?? 0;
                                }
                                );
                            }
                            );
                            ticket.arrival?.flightsInfo.forEach((flightInfoModel) => {
                                durationMinutes += flightInfoModel.durationMinutes ?? 0;
                            }
                            );
                            ticket.durationMinutes = durationMinutes;
                            ticket.session = value.session;
                            tickets.push(ticket);

                            const newCompany: AviaCompany = {
                                code: ticket.carrierCode,
                                name: ticket.carrierName,
                                isSelected: true
                            };
                            if (!companies.some((s) => s.code == newCompany.code)) {
                                companies.push(newCompany);
                            }

                            ticket.departure.forEach((ticket) => {
                                const newTicketPrice: TicketPrice = {
                                    price: ticket.price,
                                    priceFormatted: ticket.priceFormatted,
                                    date: ticket.departureDate,
                                    isSelected: false
                                };
                                if (!ticketPrices.some((s) => s.price == newTicketPrice.price && s.date == newTicketPrice.date)) {
                                    ticketPrices.push(newTicketPrice);
                                    if (maxPrice < newTicketPrice.price)
                                        maxPrice = newTicketPrice.price;
                                }
                            });


                        }

                    }
                    else {
                        let durationMinutes = 0;
                        arrivals.forEach((element) => {
                            const ticket: Ticket = { departure: [] };
                            ticket.arrival = FlightSearchService.convertArrivalSegmentToSegmentModel(element);
                            ticket.carrierCode = carrier.carrierCode;
                            ticket.carrierLogo = carrier.carrierLogo;
                            ticket.carrierName = carrier.carrierName;
                            ticket.minPrice = carrier.minPrice;
                            ticket.arrival.flightsInfo.forEach((flightInfoModel) => {
                                durationMinutes += flightInfoModel.durationMinutes ?? 0;
                            });
                            ticket.durationMinutes = durationMinutes;
                            ticket.session = value.session;
                            tickets.push(ticket);
                            durationMinutes = 0;

                            const newCompany: AviaCompany = {
                                code: ticket.carrierCode,
                                name: ticket.carrierName,
                                isSelected: true
                            };
                            if (!companies.some((s) => s.code == newCompany.code)) {
                                companies.push(newCompany);
                            }

                            const newTicketPrice: TicketPrice = {
                                price: ticket.arrival.price,
                                priceFormatted: ticket.arrival.priceFormatted,
                                date: ticket.arrival.departureDate,
                                isSelected: false
                            };
                            if (!ticketPrices.some((s) => s.price == newTicketPrice.price && s.date == newTicketPrice.date)) {
                                ticketPrices.push(newTicketPrice);

                                if (maxPrice < newTicketPrice.price)
                                    maxPrice = newTicketPrice.price;
                            }
                        });
                    }
                }
            }
        }

        return {
            tickets: tickets,
            hasResult: tickets.length > 0,
            companies: companies,
            prices: ticketPrices,
            maxPrice: maxPrice,
            session: value.session,
            searchParameter: value.searchParameter
        }
    },

    convertArrivalSegmentToSegmentModel: (arrival: Segment): Segment => {
        const segmentModel: Segment = {
            arrivalAirport: arrival.arrivalAirport,
            arrivalDate: arrival.arrivalDate,
            arrivalTime: arrival.arrivalTime,
            arrivalTimestamp: arrival.arrivalTimestamp,
            baggage: arrival.baggage,
            buyId: arrival.buyId,
            class: arrival.class,
            refundPolicy: arrival.refundPolicy,
            comm: arrival.comm,
            departureAirport: arrival.departureAirport,
            departureDate: arrival.departureDate,
            departureTime: arrival.departureTime,
            departureTimestamp: arrival.departureTimestamp,
            dirNumber: arrival.dirNumber,
            discountAttention: arrival.discountAttention,
            durationFormated: arrival.durationFormated,
            fareType: arrival.fareType,
            fee: arrival.fee,
            flightNumber: arrival.flightNumber,
            priceDetails: [],
            flightsInfo: [],
            gds: arrival.gds,
            isSubsidy: arrival.isSubsidy,
            otherPriceAvailable: arrival.otherPriceAvailable,
            pccName: arrival.pccName,
            pccOffice: arrival.pccOffice,
            price: arrival.price,
            priceFareFamily: arrival.priceFareFamily,
            seatmapAvailable: arrival.seatmapAvailable,
            seats: arrival.seats,
            seatsRequired: arrival.seatsRequired,
            segmentId: arrival.segmentId,
            // serviceClass: arrival.serviceClass,
            // refundPolicy: arrival.refundPolicy,
            servicesAvailable: arrival.servicesAvailable,
            stops: arrival.stops,
            stopsLocationCodes: arrival.stopsLocationCodes,
            subsidyInstruction: arrival.subsidyInstruction,
            tariff: arrival.tariff,
            priceFormatted: arrival.priceFormatted
        };

        arrival.priceDetails.forEach((element1) => {
            segmentModel.priceDetails.push({
                baseAmount: element1.baseAmount,
                baseTotal: element1.baseTotal,
                feeAmount: element1.feeAmount,
                feeTotal: element1.feeTotal,
                taxAmount: element1.taxAmount,
                taxTotal: element1.taxTotal,
                qty: element1.qty,
                singleAmount: element1.singleAmount,
                totalAmount: element1.totalAmount,
            });
        });

        arrival.flightsInfo.forEach((element1) => {
            segmentModel.flightsInfo.push({
                departureCountry: element1.departureCountry,
                departureCity: element1.departureCity,
                departureCityCode: element1.departureCityCode,
                departureAirport: element1.departureAirport,
                departureTerminal: element1.departureTerminal,
                departureDate: element1.departureDate,
                departureLocalTime: element1.departureLocalTime,
                departureLocalTimestamp: element1.arrivalLocalTimestamp,
                departureTimezone: element1.departureTimezone,

                arrivalCountry: element1.arrivalCountry,
                arrivalCity: element1.arrivalCity,
                arrivalCityCode: element1.arrivalCityCode,
                arrivalAirport: element1.arrivalAirport,
                arrivalTerminal: element1.arrivalTerminal,
                arrivalDate: element1.arrivalDate,
                arrivalLocalTime: element1.arrivalLocalTime,
                arrivalLocalTimestamp: element1.arrivalLocalTimestamp,
                arrivalTimezone: element1.arrivalTimezone,

                flightNumberPrint: element1.flightNumberPrint,
                durationFormated: element1.durationFormated,
                stopTime: element1.stopTime,
                airplaneCode: element1.airplaneCode,
                airplaneName: element1.airplaneName,
                baggage: element1.baggage,
                bookingClass: element1.bookingClass,
                //delays: element1.delays,
                flightNumber: element1.flightNumber,
                durationMinutes: element1.durationMinutes,
                stopTimeMinutes: element1.stopTimeMinutes,
                marketingAirlineCode: element1.marketingAirlineCode,
                marketingAirlineLogo: element1.marketingAirlineLogo,
                marketingAirlineName: element1.marketingAirlineName,
                operatingAirlineCode: element1.operatingAirlineCode,
                operatingAirlineLogo: element1.operatingAirlineLogo,
                operatingAirlineName: element1.operatingAirlineName,
                serviceClass: element1.serviceClass,
            });
        });

        return segmentModel;
    },

    convertDepartureSegmentsToSegmentModels: (departure: Segment[]): Segment[] => {
        const segments: Segment[] = [];
        departure.forEach((element) => {
            segments.push(FlightSearchService.convertArrivalSegmentToSegmentModel(element));
        });
        return segments;
    },
}

<template>
  <div class="container mx-auto mb-10 sm:mb-14">
    <h2>{{ $t('directions.popular') }}</h2>

    <div class="flex flex-wrap -mx-2 sm:-mx-4 mb-2">
      <div class="w-full md:w-6/12 px-2 sm:px-4 mb-4 sm:mb-8">
        <RouterLink
          :to="{name: 'flightDirection', params: {fromCode: 'tas', toCode: 'mow', lang: $route.params.lang}}"
          class="block hover:brightness-125 hover:shadow-xl transition-all duration-300"
        >
          <div class="bg-cover rounded-lg min-h-[256px] overflow-hidden flex location-mow">
            <div class="w-full flex items-center justify-between mt-auto py-2.5 px-6 text-white bg-body bg-opacity-60">
              <p>{{ $t('countries.russia') }}<strong class="block text-base sm:text-xl mt-1">{{ $t('directions.locations.mow.name') }}</strong></p>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="w-6/12 md:w-6/12 px-2 sm:px-4 mb-4 sm:mb-8">
        <RouterLink
          :to="{name: 'flightDirection', params: {fromCode: 'tas', toCode: 'led', lang: $route.params.lang}}"
          class="block hover:brightness-125 hover:shadow-xl transition-all duration-300"
        >
          <div class="bg-cover rounded-lg min-h-[256px] overflow-hidden flex location-led">
            <div class="w-full flex items-center justify-between mt-auto py-2.5 px-6 text-white bg-body bg-opacity-60">
              <p>{{ $t('countries.russia') }}<strong class="block text-base sm:text-xl mt-1">{{ $t('directions.locations.led.name') }}</strong></p>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="w-6/12 md:w-4/12 px-2 sm:px-4 mb-4 sm:mb-8">
        <RouterLink
          :to="{name: 'flightDirection', params: {fromCode: 'tas', toCode: 'ovb', lang: $route.params.lang}}"
          class="block hover:brightness-125 hover:shadow-xl transition-all duration-300"
        >
          <div class="bg-cover rounded-lg min-h-[256px] overflow-hidden flex location-ovb">
            <div class="w-full flex items-center justify-between mt-auto py-2.5 px-6 text-white bg-body bg-opacity-60">
              <p>{{ $t('countries.russia') }}<strong class="block text-base sm:text-xl mt-1">{{ $t('directions.locations.ovb.name') }}</strong></p>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="w-6/12 md:w-4/12 px-2 sm:px-4 mb-4 sm:mb-8">
        <RouterLink
          :to="{name: 'flightDirection', params: {fromCode: 'tas', toCode: 'ist', lang: $route.params.lang}}"
          class="block hover:brightness-125 hover:shadow-xl transition-all duration-300"
        >
          <div class="bg-cover rounded-lg min-h-[256px] overflow-hidden flex location-ist">
            <div class="w-full flex items-center justify-between mt-auto py-2.5 px-6 text-white bg-body bg-opacity-60">
              <p>{{ $t('countries.turkey') }}<strong class="block text-base sm:text-xl mt-1">{{ $t('directions.locations.ist.name') }}</strong></p>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="w-6/12 md:w-4/12 px-2 sm:px-4 mb-4 sm:mb-8">
        <RouterLink
          :to="{name: 'flightDirection', params: {fromCode: 'tas', toCode: 'ikt', lang: $route.params.lang}}"
          class="block hover:brightness-125 hover:shadow-xl transition-all duration-300"
        >
          <div class="bg-cover rounded-lg min-h-[256px] overflow-hidden flex location-ikt">
            <div class="w-full flex items-center justify-between mt-auto py-2.5 px-6 text-white bg-body bg-opacity-60">
              <p>{{ $t('countries.russia') }}<strong class="block text-base sm:text-xl mt-1">{{ $t('directions.locations.ikt.name') }}</strong></p>
            </div>
          </div>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<style scoped>
.location-mow {
  background-image: url('../assets/img/popular-routes/moscow.jpg');
}
.location-led {
  background-image: url('../assets/img/popular-routes/st-peterburg.jpg');
}
.location-ist {
  background-image: url('../assets/img/popular-routes/istanbul.jpg');
}
.location-ikt {
  background-image: url('../assets/img/popular-routes/irkutsk.jpg');
}
.location-ovb {
  background-image: url('../assets/img/popular-routes/novosibirsk.jpg');
}
</style>

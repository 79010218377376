import {ref} from "vue";
import {searchApi} from "@/services/railway_search";

export function filterable(){
    const {searchRes} = searchApi
    const Suite = ref(false)
    const Coupe = ref(false)
    const reservedSeat = ref(false)
    const Sedentary = ref(false)
    const filterRailway = () =>{
        // if (Suite.value){
        //
        // }
    }

    return{
        Suite,
        Coupe,
        reservedSeat,
        Sedentary
    }
}
export const filterData = filterable()

<template>
  <div>
    <svg
      fill="none"
      height="18"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.857 8.129h22.286c.473 0 .857.39.857.87a.864.864 0 01-.857.872H.857A.864.864 0 010 9c0-.481.384-.871.857-.871zM23.143 1.742H.857A.864.864 0 010 .871C0 .39.384 0 .857 0h22.286c.473 0 .857.39.857.871a.864.864 0 01-.857.871zM.857 16.258h22.286c.473 0 .857.39.857.87a.864.864 0 01-.857.872H.857A.864.864 0 010 17.129c0-.481.384-.871.857-.871z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "burgermenuIndex",
  props: {
    color: { type: String, default: '#fff' },
  }
}
</script>

<style scoped>

</style>

import axios, { AxiosRequestConfig } from "axios"

//axios.defaults.baseURL = 'https://localhost:7704/api';
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
    async get(url: string, config?: AxiosRequestConfig): Promise<any> {
        const res = await axios.get(url, config);
        return res.data;
    },
    async post(url: string, data: any, config?: AxiosRequestConfig): Promise<any> {
        const res = await axios.post(url, data, config);
        return res.data;
    },
    async update(url: string, data: any, config?: AxiosRequestConfig): Promise<any> {
        const res = await axios.put(url, data, config);
        return res.data;
    },
    async delete(url: string, config?: AxiosRequestConfig): Promise<any> {
        const res = await axios.delete(url, config);
        return res.data;
    }
}


import {defineComponent, PropType} from "vue";
import {Segment} from "@/models/flight_models";

export default defineComponent({
  name: "SearchResultMoreItem",
  props: {
    'segment': {type: Object as PropType<Segment>}
  },
  computed: {
    flightTitle(): string {
      let title = "";
      if (this.segment) {
        title = this.segment.departureAirport + ' - ' + this.segment.arrivalAirport;
        if (this.segment.flightsInfo.length > 0) {
          const firstFlight = this.segment.flightsInfo[0];
          const lastFlight = this.segment.flightsInfo[this.segment.flightsInfo.length - 1];
          title = [firstFlight.departureCity, ' - ', lastFlight.arrivalCity].join('');
        }
      }
      return title;
    },
    flightDateInfo(): string {
      if (this.segment) {
        return [this.segment.departureDate, ', ', this.segment.durationFormated].join('');
      }
      return "";
    }
  },
})
;

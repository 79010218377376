import api from './api_request';
import {
    RegisterRequest,
    RegisterResponse,
    SendMeCodeRequest,
    SendMeCodeResponse,
    VerifyMeRequest
} from "@/models/auth/auth_models";
import { VerifyPhoneNumberResponse } from "@/models/auth/user_models";
import { AxiosRequestConfig } from "axios";
import { LocalDb } from "@/repository/localdb";
import { isStringNullOrEmpty } from "@/helpers/general_helper";

export const AuthService = {
    authHeader: (): AxiosRequestConfig => {
        const tokenData = LocalDb.getToken();
        return { headers: { 'Authorization': 'bearer ' + tokenData?.token } };
    },
    isAuthenticated: function (): boolean {
        const token = LocalDb.getToken();
        const currentUser = LocalDb.getCurrentUser();
        if (token == null || currentUser == null || isStringNullOrEmpty(token.token))
            return false;
        else
            return true;
    },
    sendMeCode: async (request: SendMeCodeRequest): Promise<SendMeCodeResponse> => {
        const data = await api.post('/auth/sendmecode', request)
            .then(response => {
                return response;
            })
        return data;
    },
    verifyMe: async (request: VerifyMeRequest): Promise<VerifyPhoneNumberResponse> => {
        const data = await api.post('/auth/verifyme', request)
            .then(response => {
                return response;
            })
        return data;
    },
    register: async (request: RegisterRequest): Promise<RegisterResponse> => {
        const data = await api.post('/auth/register', request)
            .then(response => {
                return response;
            })
        return data;
    },
}

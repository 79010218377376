
import {defineComponent, PropType, toRaw} from "vue";
import {AviaCompany, FlightSearchResult, Ticket, TicketPrice} from "@/models/flight_models";
import {FlightSort} from "@/models/flight_sort_model";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "filter_panel",
  props: {
    isFilterOpen:Boolean,
    'searchResult': {type: Object as PropType<FlightSearchResult>}
  },
  data() {
    return {
      companies: {} as AviaCompany[],
      ticketPrices: {} as TicketPrice[],
      hasBaggage: false,
      noAirportChange: false,
      directFlight: false,
      sorting: {} as FlightSort,

      // isFilterOpen: false,
      isTopFilterOpen: true,
      isTransferOpen: true,
      isDurationOpen: true,
      isAirlineOpen: true,
      isPaymentTypeOpen: true,
      isAirportOpen: true,
      isTransplantDurationOpen: true,
    }
  },

  mounted() {
    if (this.searchResult) {
      this.companies = this.searchResult.companies;
      this.ticketPrices = this.searchResult.prices;
      this.sorting = FlightSort.BY_PRICE_LOW;
    }
  },
  watch: {
    'searchResult.tickets': {
      handler: function (after, before) {
        if (this.searchResult) {
          this.companies = this.searchResult.companies;
          this.ticketPrices = this.searchResult.prices;
          this.sorting = FlightSort.BY_PRICE_LOW;
          this.hasBaggage = false;
          this.noAirportChange = false;
          this.directFlight = false;
        }
      },
      deep: true
    },
    ticketPrices: {
      handler: function (after, before) {
        this.filterResult();
      },
      deep: true
      
    },
    companies: {
      handler: function (after, before) {
        this.filterResult();
      },
      deep: true
    },
    directFlight: function (after, before) {
      this.filterResult();
    },
    noAirportChange: function (after, before) {
      this.filterResult();
    },
    hasBaggage: function (after, before) {
      this.filterResult();
    },
    sorting: function (after, before) {
      this.filterResult();
    },
  },
  methods: {
    openFalse(event: any){
      this.$emit('openFalse', event.target.value)
    },
    filterResult() {
      if (this.searchResult) {
        let filtered: Ticket[] = this.searchResult.tickets;
        filtered = this.filterByPrice(filtered);
        filtered = this.filterByBaggage(filtered);
        filtered = this.filterByNoAirportChange(filtered);
        filtered = this.filterByDirectFlight(filtered);
        filtered = this.filterByCompanies(filtered);
        filtered = this.filterBySorting(filtered);
        this.$emit('filter-changed', filtered);
      }
    },
    clearFilters() {
      this.hasBaggage = false
      this.noAirportChange = false
      this.directFlight = false
      this.sorting = FlightSort.BY_PRICE_LOW;

      this.companies = this.companies?.map(company => ({
        ...company,
        isSelected: true
      }))
    },
    filterByPrice(tickets: Ticket[]): Ticket[] {
      let result: Ticket[] = tickets;
      if (tickets && tickets.length > 0) {
        let selectedPrices: TicketPrice[] = this.ticketPrices.filter((f) => f.isSelected == true);
        if (selectedPrices.length > 0) {
          let isSatisfied = true;
          result = tickets.filter((ticket) => {
            isSatisfied = false;
            selectedPrices.forEach((ticketPrice) => {
              if (ticket.arrival) {
                if (ticket.arrival.price == ticketPrice.price) {
                  isSatisfied = true;
                  return;
                }
              } else if (ticket.departure) {
                ticket.departure.forEach((segment) => {
                  if (segment.price == ticketPrice.price) {
                    isSatisfied = true;
                    return;
                  }
                });
              }
            });
            return isSatisfied;
          });
        }
      }
      return result;
    },
    filterByCompanies(tickets: Ticket[]): Ticket[] {
      let result: Ticket[] = tickets;
      if (tickets) {
        let isSatisfied = true;
        result = tickets.filter((ticket) => {
          isSatisfied = false;
          if (this.companies.some((s) => s.isSelected && s.code == ticket.carrierCode))
            isSatisfied = true;
          return isSatisfied;
        });
      }
      return result;
    },
    filterByDirectFlight(tickets: Ticket[]): Ticket[] {
      let result: Ticket[] = tickets;
      if (tickets && this.directFlight) {
        let isSatisfied = true;
        result = tickets.filter((ticket) => {
          isSatisfied = false;
          if (ticket.arrival) {
            if (ticket.arrival.stops == 0) {
              isSatisfied = true;
            }
          } else if (ticket.departure) {
            ticket.departure.forEach((segment) => {
              if (segment.stops == 0) {
                isSatisfied = true;
              }
            });
          }
          return isSatisfied;
        });
      }
      return result;
    },
    filterByNoAirportChange(tickets: Ticket[]): Ticket[] {
      let result: Ticket[] = tickets;

      if (tickets && this.noAirportChange) {
        let isSatisfied = true;
        result = tickets.filter((ticket) => {
          if (ticket.arrival) {
            for (let i = 0; i < ticket.arrival?.flightsInfo?.length - 1; i++) {
              if (ticket.arrival.flightsInfo[i].arrivalAirport != ticket.arrival.flightsInfo[i + 1].departureAirport) {
                isSatisfied = false;
                break;
              }
            }
          } else if (ticket.departure) {
            ticket.departure?.forEach((segment) => {
                  for (let i = 0; i < segment.flightsInfo.length - 1; i++) {
                    if (segment.flightsInfo[i].arrivalAirport != segment.flightsInfo[i + 1].departureAirport) {
                      isSatisfied = false;
                      break;
                    }
                  }
                }
            );
          }
          return isSatisfied;
        });
      }

      return result;
    },
    filterByBaggage(tickets: Ticket[]): Ticket[] {
      let result: Ticket[] = tickets;

      if (tickets && this.hasBaggage) {
        const _baggageCounts = ['0PC', 'NO','0 PC', '','0 Kg', '0Kg'];
        let bothHasBaggage = false;

        result = tickets.filter((ticket) => {
          bothHasBaggage = false;
          if (ticket.arrival?.baggage != null && !_baggageCounts.includes(ticket.arrival.baggage)) {
            bothHasBaggage = true;
          }
          if (ticket.departure) {
            ticket.departure.forEach((element) => {
              if (_baggageCounts.includes(element.baggage)) {
                bothHasBaggage = false;
                return;
              }
            });
          }
          return bothHasBaggage;
        });
      }
      return result;
    },
    filterBySorting(tickets: Ticket[]): Ticket[] {
      let result: Ticket[] = tickets;

      if (tickets && tickets.length > 0 && this.sorting) {
        if (this.sorting === FlightSort.BY_PRICE_LOW) {
          result = tickets.slice().sort((a, b) => {
            if (a.arrival && b.arrival)
              return a.arrival.price > b.arrival.price ? 1 : -1
            return -1;
          });
        } else if (this.sorting === FlightSort.BY_PRICE_HIGH) {
          result = tickets.slice().sort((a, b) => {
            if (a.arrival && b.arrival)
              return a.arrival.price < b.arrival.price ? 1 : -1
            return -1;
          });
        } else if (this.sorting === FlightSort.BY_DURATION) {
          result = tickets.slice().sort((a, b) => {
            if (a.durationMinutes && b.durationMinutes)
              return a.durationMinutes > b.durationMinutes ? 1 : -1
            return -1;
          });
        } else if (this.sorting === FlightSort.EARLY_DEPARTURE) {
          result = tickets.slice().sort((a, b) => {
            return this.departureTime(a) > this.departureTime(b) ? 1 : -1
          });
        } else if (this.sorting === FlightSort.LATE_DEPARTURE) {
          result = tickets.slice().sort((a, b) => {
            return this.departureTime(a) < this.departureTime(b) ? 1 : -1
          });
        }
      }
      return result;
    },
    departureTime(a: Ticket): number {
      let _departureTime = 0;
      if (a.departure && a.departure.length > 0) {
        _departureTime = a.departure[0].departureTimestamp;
      } else {
        _departureTime = a.arrival?.departureTimestamp ?? 0;
      }
      return _departureTime;
    },
    priceFormat(price: number): string {
      return price.toLocaleString('uz-UZ', {
        style: 'currency',
        currency: 'UZS',
        minimumFractionDigits: 0
      })
    },
    calculateHeight(currentPrice: number, maxPrice: number): number {
      return (currentPrice / maxPrice) * 300;
    }
  },
});

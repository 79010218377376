import api from './api_request';
import { CollectionResponse } from "@/models/collection_models";

export const CollectionService = {
    getCollections: async (): Promise<CollectionResponse> => {
        const data = await api.get('/collection/get')
            .then(response => {
                return response;
            });
        return data;
    }
}
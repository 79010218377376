import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import FlightSearchResults from "@/views/FlightSearchResults.vue";
import RailwaySearchResult from "@/views/RailwaySearchResult.vue"
import HomeRailway from '@/views/HomeRailway.vue'
import App from "@/App.vue";

const routes: Array<RouteRecordRaw> = [{
  path: '/:lang(en|uz)?',
  component: App,
  children: [
    {
      path: '',
      name: 'home',
      component: Home
    },
    {
      path: 'railway',
      name: 'railway',
      component: HomeRailway
    },
    {
      path: 'charter-flights',
      name: 'charterFlights',
      component: () => import('../views/FlightSearchCharter.vue')
    },
    {
      //http://localhost:8080/flight/direction=TAS20211123VKO-VKO20211126TAS&cls=E&adt=1&cld=0&inf=0
      path: 'flight/search/:searchParams',
      name: 'searchResults',
      component: FlightSearchResults
    },
    {
      path: 'railway/search/:searchParams',
      name: 'railwaySearchResults',
      component: RailwaySearchResult
    },
    {
      path: 'tours',
      name: 'tours',
      component: () => import('../views/Tours.vue')
    },
    {
      path: 'profile/orders',
      name: 'profileOrders',
      component: () => import('../views/profile/flight-orders.vue')
    },
    {
      path: 'flight/booking',
      name: 'flight_booking',
      component: () => import('../views/flight/booking.vue')
    },
    {
      path: 'railway/booking',
      name: 'railway_booking',
      component: () => import('../views/railway/booking.vue')
    },
    {
      path: 'flight/payment/:id',
      name: 'flight_booking_payment',
      component: () => import('../views/flight/payment.vue')
    },
    {
      path: 'railway/payment/:id',
      name: 'railway_booking_payment',
      component: () => import('../views/railway/payment.vue')
    },
    {
      //http://localhost:8080/profile/orders/123
      path: 'profile/orders/:id',
      name: 'flight_order_details',
      component: () => import('../views/profile/flight-order-details.vue')
    },
    {
      //http://localhost:8080/flight/payment/confirm/123
      path: 'flight/payment/confirm/:id',
      name: 'payment_confirm',
      component: () => import('../views/flight/payment_confirm.vue')
    },
    {
      path: 'profile/passengers',
      name: 'flight-passengers',
      component: () => import('../views/profile/flight-passengers.vue')
    },
    {
      path: 'about',
      name: 'about',
      component: () => import('../views/CompanyAbout.vue')
    },
    {
      path: 'public-offer',
      name: 'publicOffer',
      component: () => import('../views/CompanyPublicOffer.vue')
    },
    {
      path: 'privacy',
      name: 'privacy',
      component: () => import('../views/CompanyPrivacy.vue')
    },
    {
      path: 'direction/:fromCode/:toCode',
      name: 'flightDirection',
      component: () => import('../views/FlightDirections.vue'),
      props: true
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound', component: () => import('../views/NotFound.vue')
    },

  ]
}];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0,
        behavior: 'smooth',
      }
    }
  },
})

export default router

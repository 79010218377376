import { onBeforeMount, onMounted, onUpdated, PropType, reactive, readonly, toRefs, watch } from "vue";
import Swal from 'sweetalert2'
import { Direction, IATALocation, SearchParameter } from "@/models/search_parameters";
import { CollectionResponse, FlightClass, PassengerTypeQty } from "@/models/collection_models";
import { LocalDb } from "@/repository/localdb";
import { AvioneMessage } from "@/constants/error_messages";
import { CollectionService } from "@/services/collection_service";

const state = reactive({
    loading: false,
    isComplexSearch: false,
    fromLocationErrorMessage: '',
    toLocationErrorMessage: '',
    searchParameter: {} as SearchParameter,
    collections: {} as CollectionResponse | null,
});

export function itemStore() {
    return {
        state: toRefs(state),
    }
}

export default function searchPanelController(isHome = false, props = {
    predefinedFromLocation: {} as IATALocation | undefined,
    predefinedToLocation: {} as IATALocation | undefined
}) {

    onBeforeMount(async () => {
        const value = LocalDb.getSearchParameters();
        if (value) {
            state.searchParameter = value;
        } else {
            state.searchParameter = new SearchParameter();
            onAddFlightDirection();
        }
        state.isComplexSearch = state.searchParameter.directions.length > 1
    });

    watch(() => props.predefinedFromLocation, (from) => {
        if (from && Object.keys(from).length !== 0) {
            onSetFromLocationEventHandler(from, 0);
        }
    });

    watch(() => props.predefinedToLocation, (to) => {
        if (to && Object.keys(to).length !== 0) {
            onSetToLocationEventHandler(to, 0);
        }
    });

    watch(() => state.searchParameter, (first, second) => {
        LocalDb.saveSearchParameters(state.searchParameter);
    },
        { deep: true },
    );

    const onSwitchSearchPanel = (isComplex: boolean) => {
        state.isComplexSearch = isComplex;
        state.searchParameter.directions.splice(1, state.searchParameter.directions.length - 1);
        if (isComplex && state.searchParameter.directions.length == 1) {
            state.searchParameter.directions[0].selectedToDate = "";
        }
    }

    const onAddFlightDirection = () => {
        const newDirection = new Direction(state.searchParameter.directions.length);
        state.searchParameter.directions.push(newDirection);
    }

    const onRemoveFlightDirection = (index: number) => {
        if (index >= 0) {
            for (let i = 0; i < state.searchParameter.directions.length; i++) {
                if (index == state.searchParameter.directions[i].index) {
                    state.searchParameter.directions.splice(i, 1);
                }
            }
        }
    }

    const doSearch = () => {
        try {
            validate();
            //http://localhost:8080/flight/search/direction=TAS20211227SVO-SVO20211228DXB-DXB20211230TAS&cls=E&adt=1&cld=0&inf=0
            //string input = "TAS20200825DME-DME20200827YXU-YXU20200828CDG";
            let directionUrl = "/flight/search/direction=";

            if (state.searchParameter.directions.length == 1) {
                const direction: Direction = state.searchParameter.directions[0];
                let isLocationValid = true;

                if (direction.selectedFromLocation == null) {
                    state.fromLocationErrorMessage = 'search.SelectYourDeparturePoint';
                    isLocationValid = false;
                }
                if (direction.selectedToLocation == null) {
                    state.toLocationErrorMessage = 'search.ChoosePlaceOfArrival';
                    isLocationValid = false;
                }

                if (!isLocationValid)
                    return;
                if (direction.selectedFromDate) {
                    directionUrl += direction.selectedFromLocation?.airport.code + direction.selectedFromDate.replace(/-/g, '') + direction.selectedToLocation?.airport.code;
                }
                if (direction.selectedToDate) {
                    directionUrl += "-" + direction.selectedToLocation?.airport.code + direction.selectedToDate.replace(/-/g, '') + direction.selectedFromLocation?.airport.code;
                }
            } else {
                for (const direction of state.searchParameter.directions) {
                    if (direction.selectedFromDate) {
                        if (direction.index > 0)
                            directionUrl += "-";
                        directionUrl += direction.selectedFromLocation?.airport.code + direction.selectedFromDate.replace(/-/g, '') + direction.selectedToLocation?.airport.code;
                    }
                }
            }

            directionUrl += "&cls=" + state.searchParameter.selectedClasses?.code;
            for (const c of state.searchParameter.selectedPassengers) {
                if (c.code == 'adult_qnt') directionUrl += "&adt=" + c.qty;
                else if (c.code == 'child_qnt') directionUrl += "&cld=" + c.qty;
                else if (c.code == 'infant_qnt') directionUrl += "&inf=" + c.qty;
            }

            window.location.href = directionUrl;
        } catch (e) {
            Swal.fire('', e.message, 'warning');
        }
    }

    const onSetFromLocationEventHandler = (location: IATALocation, index: number) => {
        state.searchParameter.directions[index].selectedFromLocation = location;
    }

    const onSetToLocationEventHandler = (location: IATALocation, index: number) => {
        state.searchParameter.directions[index].selectedToLocation = location;
    }

    // const initLoad = () => {
    //     let fromDateFieldId = '';
    //     let toDateFieldId = '';
    //     const extra = !state.isComplexSearch ? '_simple' : '';
    //
    //     for (const direction of state.searchParameter.directions) {
    //         fromDateFieldId = '#txtFromDate_' + direction.index + extra
    //         toDateFieldId = '#txtToDate_' + direction.index + extra
    //         initDatePicker(fromDateFieldId, () => {
    //             const dateFormatted = new Date(direction.selectedFromDate).toLocaleDateString('en-CA');
    //             direction.selectedFromDate = dateFormatted; //yyyy-mm-dd
    //         });
    //         initDatePicker(toDateFieldId,  () => {
    //             const dateFormatted = new Date(direction.selectedToDate).toLocaleDateString('en-CA');
    //             direction.selectedToDate = dateFormatted; //yyyy-mm-dd
    //         });
    //     }
    // }

    const loadCollections = async () => {
        state.collections = LocalDb.getCollections();
        if (state.collections == null) {
            state.collections = await CollectionService.getCollections();
            LocalDb.saveCollections(state.collections);

            //SHI: set default value if no class found by default
            if (state.searchParameter.selectedClasses == null) {
                state.searchParameter.selectedClasses = state.collections.flightClasses.find(w => w.isDefault == true) ?? null;
            }
        }
    }

    const validate = () => {
        // if (state.searchParameter.selectedFromLocation == null || state.searchParameter.selectedToLocation == null)
        //     throw new Error(AvioneMessage.SELECT_FLIGHT_LOCATION);
        //
        // if (!state.searchParameter.selectedFromDate)
        //     throw new Error(AvioneMessage.SELECT_DEPARTURE_DATE);
    }

    return {
        state: toRefs(state),
        doSearch,
        loadCollections,
        onSetFromLocationEventHandler,
        onSetToLocationEventHandler,
        onAddFlightDirection,
        onSwitchSearchPanel,
        onRemoveFlightDirection,
    }
}

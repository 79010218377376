
import {defineComponent} from "vue";
import searchPanelController from "@/composables/search_panel";

export default defineComponent({
  name: "LocationHistory",
  setup() {
    const {
      state,
      doSearch,
      onSetFromLocationEventHandler,
      onSetToLocationEventHandler,
      onAddFlightDirection,
      onSwitchSearchPanel,
      onRemoveFlightDirection,
    } = searchPanelController();

    return {
      ...state,
      doSearch,
      onSetFromLocationEventHandler,
      onSetToLocationEventHandler,
      onAddFlightDirection,
      onSwitchSearchPanel,
      onRemoveFlightDirection,
    };
  },
});

import { ref } from "vue";
import { LocalDb } from "@/repository/localdb";
import api from "@/services/api_request";

const doOrderForm = ref({
    backward: false,
    withInsurance: false,
    withSmsNotification: false,
    ordersRequest: [
        {
            stationFrom: "",
            stationTo: "",
            depDate: "",
            train: {
                number: "",
            },
            car: {
                type: "",
                number: "",
            },
            requirements: {
                seatsRange: "",
            },
        },
    ],
    passengers: [] as any[],
});
const doOrderRes = ref([])

export function doOrderServise() {
    const passengerss: any = ref([])
    const tokenData = LocalDb.getToken();
    const savedoOrder = async (trainRes: any) => {
        doOrderForm.value.ordersRequest[0].train.number = trainRes.number
        const response = await api.post('/railway/do-order', doOrderForm.value, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then((res) => {
                res
            })
    }



    return {
        savedoOrder,
        doOrderForm,
        doOrderRes,
        passengerss
    }

}

export const doOrderApi = doOrderServise()

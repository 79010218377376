
import {defineComponent, PropType} from "vue";
import {Segment, Ticket} from "@/models/flight_models";
import {FlightSort} from "@/models/flight_sort_model";

export default defineComponent({
      name: "check_availability",
      props: {
        "show": Object as PropType<boolean>,
      },
    }
);

export const AvioneMessage = {
    TARIF_NOT_SELECTED: "AvioneMessage.selectRate",
    CURRENT_USER_NOT_FOUND: "AvioneMessage.loginAgain",
    BOOKING_TICKET_NOT_FOUNT: "AvioneMessage.bookingTicketNotFound",
    PASSENGER_FIRSTNAME_REQUIRED: "AvioneMessage.EnterPassengerName",
    PASSENGER_LASTNAME_REQUIRED: "AvioneMessage.EnterPassengersLastName",
    PASSENGER_MIDDLENAME_REQUIRED: "AvioneMessage.eneterMiddleNamePassenger",
    PASSENGER_BIRTHDATE_REQUIRED: "AvioneMessage.EnterPassengersDateOfBirth",
    PASSENGER_GENDER_REQUIRED: "AvioneMessage.ChooseGender",
    PASSENGER_CITIZENSHIP_REQUIRED: "AvioneMessage.SelectNationality",
    PASSENGER_PHONENUMBER_REQUIRED: "AvioneMessage.EnterPassengersPhoneNumber",
    PASSENGER_DOCUMENTTYPE_REQUIRED: "AvioneMessage.SelectDocumentType",
    PASSENGER_PASSPORNUMBER_REQUIRED: "AvioneMessage.EnterDocumentNumber",
    INVALID_PHONENUMBER: "AvioneMessage.WrongPhoneNumber",
    PAYMENT_TYPE_CHOOSE: "AvioneMessage.PleaseSelectPaymentMethod",
    PAYMENT_TYPE_PAYMO_REDIRECTION: "AvioneMessage.WeWillRedirectYouPaymoPageForPayment",
    PAYMENT_STATUS_CHECKING: "AvioneMessage.CheckingBillPayments",
    PAYMENT_STATUS_DISCHARGED: "AvioneMessage.discharged",
    PAYMENT_STATUS_CHECKING_DISCHARGE: "AvioneMessage.PAYMENT_STATUS_CHECKING_DISCHARGE",
    PAYMENT_STATUS_SUCCESS: "AvioneMessage.PAYMENT_STATUS_SUCCESS",
    PAYMENT_COULD_NOT_CONFIRM: "AvioneMessage.verifyYourPayments",
    PLEASE_WAIT: "AvioneMessage.PleaseWait",
    ORDER_NOT_FOUND: "AvioneMessage.OrderNotFound",
    SELECT_FLIGHT_LOCATION: 'AvioneMessage.SELECT_FLIGHT_LOCATION',
    SELECT_DEPARTURE_DATE: 'AvioneMessage.SELECT_DEPARTURE_DATE',
    WRONG_SMS_CODE: 'AvioneMessage.WrongCode',
    SMS_CODE_NOT_CORRECT: 'AvioneMessage.PleaseEnterSMSCode',
    PHONE_NUMBER_FORMAT_NOT_FOUND: 'AvioneMessage.unable',
    //PHONE_NUMBER_FORMAT_NOT_FOUND: 'Нам не удалось определить формат вашего номера телефона',
    PHONE_NUMBER_REQUIRED: 'AvioneMessage.PHONE_NUMBER_REQUIRED',
    ALL_FIELDS_REQUIRED: 'AvioneMessage.AllFieldsRequired',
    TICKET_PRICE_CHANGED_TITLE: 'AvioneMessage.TICKET_PRICE_CHANGED_TITLE',
    TICKET_PRICE_CHANGED: "AvioneMessage.TICKET_PRICE_CHANGED",
    NEW_PRICE: "Новая цена {}",
    OLD_PRICE: "Старая цена {}",
    CONTINUE: "AvioneMessage.CONTINUE",
    PAY_LATER: "AvioneMessage.PAY_LATER",
    COULD_NOT_DELETE_ORDER: "AvioneMessage.WeWereUnable",
    AUTHENTICATION_REQUIRED_FOR_BOOKING: 'AvioneMessage.PleaseLogIn',
    PAYMENT_BOOKING_VOID_MESSAGE: "AvioneMessage.sureToCancelBooking",
    PAYMENT_BOOKING_VOID_SUCCESS: "AvioneMessage.PAYMENT_BOOKING_VOID_SUCCESS",
    TICKET_VOIDING: "AvioneMessage.TICKET_VOIDING",
    PAYMENT_BOOKING_VOID_FAIL: "AvioneMessage.PAYMENT_BOOKING_VOID_FAIL",
    DOWNLOAD_TICKET_TEXT: 'AvioneMessage.DownloadTicket',
    PASSENGER_EMAIL_REQUIRED: 'AvioneMessage.WrongEmail',
    PASSENGER_ISSUE_REQUIRED: 'AvioneMessage.WrongPassportIssue',
    PASSENGER_EXPIRE_REQUIRED: 'AvioneMessage.WrongPassportExpire',
    NotEnoughInBalance: 'AvioneMessage.NotEnoughInBalance',
    PASSPORT_EXPITE: 'AvioneMessage.PassportExpire'
}


import {createStore, useStore as baseUseStore, Store} from "vuex";
import {InjectionKey} from "vue";
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence<any>({
    storage: window.localStorage
})

export interface State {
    count: number,
    locale: string
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        count: 0,
        locale:"en",
    },
    mutations: {
        locale(state, data){
            state.locale = data
        },
        increment(state) {
            state.count++;
        }
    },
    getters: {
        counter(state) {
            return state.count;
        }
    },
    modules: {},
    plugins: [vuexLocal.plugin]

})

// define your own `useStore` composition function
export function useStore() {
    return baseUseStore(key)
}

<template>
  <div>
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="10"
        fill="url(#paint0_linear_1_48087)"
      />
      <path
        d="M14.9208 8.29825L13.3708 6.74825C13.1986 6.57047 12.8986 6.69269 12.8986 6.94269L12.8986 7.93714L9.55972 7.93714C9.25417 7.93714 9.00417 8.18714 9.00417 8.49269C9.00417 8.79825 9.25417 9.04825 9.55972 9.04825L12.8986 9.04825L12.8986 10.0427C12.8986 10.2927 13.1986 10.4149 13.3708 10.2371L14.9208 8.68714C15.0264 8.58158 15.0264 8.4038 14.9208 8.29825ZM7.33194 12.3816L10.6708 12.3816C10.9764 12.3816 11.2264 12.1316 11.2264 11.826C11.2264 11.5205 10.9764 11.2705 10.6708 11.2705L7.33194 11.2705L7.33194 10.276C7.33194 10.026 7.03194 9.9038 6.85972 10.0816L5.31528 11.6316C5.20972 11.7427 5.20972 11.9149 5.31528 12.026L6.85972 13.576C7.03194 13.7538 7.33194 13.626 7.33194 13.3816L7.33194 12.3816Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_48087"
          x1="10"
          y1="0"
          x2="10"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3A90FF" />
          <stop
            offset="1"
            stop-color="#005FDB"
          />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>
 
 <script>
 export default {
   name: "SwapIconIndex"
 }
 </script>
 
 <style scoped>
 
 </style>
 
import api from "@/services/api_request";
import { LocalDb } from "@/repository/localdb";
import { ref } from 'vue'
import { useRouter, useRoute } from "vue-router";
const trainTypeForm = ref({
    stationFrom: '',
    stationTo: '',
    direction: [
        {
            depDate: '',
            fullDay: true,
            type: "Forward",
            train: {
                number: ''
            }
        }
    ]
})
const router = useRouter()
const route = useRoute()
const trainRes: any = ref([])
const car = ref([])
const places1 = ref([])
const placesSplit = ref('')

export function trainTypeService() {
    const tokenData = LocalDb.getToken();
    const showCheckAvailability = ref(false)
    const onBuyClickk = async (item: any) => {

        trainRes.value = false
        showCheckAvailability.value = true
        placesSplit.value.split(' ')
        trainTypeForm.value.direction[0].train.number = item.number
        const response = await api.post('/railway/trains-type/', trainTypeForm.value, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then((ress) => {

                for (let i = 0; i < ress.place.direction.length; i++) {
                    for (let j = 0; j < ress.place.direction[i].trains.length; j++) {
                        for (let car1 = 0; car1 < ress.place.direction[i].trains[j].train.cars.length; car1++) {
                            car.value = ress.place.direction[i].trains[j].train.cars[car1]
                            for (let place = 0; place < ress.place.direction[i].trains[j].train.cars[car1].car.length; place++) {
                                places1.value = ress.place.direction[i].trains[j].train.cars[car1].car[place].places.split(',')
                            }
                        }
                        trainRes.value = ress.place.direction[i].trains[j].train
                    }

                }
            })
    }
    return {
        showCheckAvailability,
        onBuyClickk,
        trainTypeForm,
        trainRes,
        car,
        places1,
    }
}
export const trainTypeApi = trainTypeService()

import { PhoneNumber, PhoneNumberUtil } from "google-libphonenumber";
import { Country } from "@/models/auth/user_models";
import { CollectionResponse } from "@/models/collection_models";
import { LocalDb } from "@/repository/localdb";
import { isStringNullOrEmpty } from "@/helpers/general_helper";
import { AvioneMessage } from "@/constants/error_messages";

export const getCountryInfoByPhoneNumber = (phoneNumber: string, vue: any): Country | null => {
    const collection: CollectionResponse | null = LocalDb.getCollections();
    if (collection) {
        const phoneNumberInfo = getPhoneNumberInfo(phoneNumber, vue);

        const country: Country | undefined = collection.countries.find((s) => s.phone === phoneNumberInfo.getCountryCode()?.toString());
        if (country)
            return country;
    }
    return null;
};

export const getPhoneNumberInfo = (phoneNumber: string, vue: any): PhoneNumber => {
    const _phoneNumber = cleanUpPhoneNumber(phoneNumber)
    const phoneUtil = PhoneNumberUtil.getInstance();
    const number = phoneUtil.parse(_phoneNumber);

    if (!phoneUtil.isValidNumber(number))
        throw new Error(vue.$t(AvioneMessage.INVALID_PHONENUMBER))

    return number;
};

export const cleanUpPhoneNumber = (number: string): string => {
    if (isStringNullOrEmpty(number))
        return "";

    number = number.toString().trim().replace('+', '');
    number = "+".concat(number);
    return number;
};

<template>
  <div class="container mx-auto mb-10 sm:mb-14">
    <h2>{{ $t("directions.popular") }}</h2>

    <div class="flex flex-wrap -mx-2 sm:-mx-4 mb-2">
      <div class="w-full md:w-6/12 px-2 sm:px-4 mb-4 sm:mb-8">
        <RouterLink
          :to="{
            name: 'flightDirection',
            params: {
              fromCode: 'tas',
              toCode: 'mow',
              lang: $route.params.lang,
            },
          }"
          class="
            block
            hover:brightness-125 hover:shadow-xl
            transition-all
            duration-300
          "
        >
          <div
            class="
              bg-cover
              rounded-lg
              min-h-[256px]
              overflow-hidden
              flex
              location-mow
            "
          >
            <div
              class="
                w-full
                flex
                items-center
                justify-between
                mt-auto
                py-2.5
                px-6
                text-white
                bg-body bg-opacity-60
              "
            >
              <p>
                Исторический<strong class="block text-base sm:text-xl mt-1">Самарканд</strong>
              </p>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="w-6/12 md:w-6/12 px-2 sm:px-4 mb-4 sm:mb-8">
        <RouterLink
          :to="{
            name: 'flightDirection',
            params: {
              fromCode: 'tas',
              toCode: 'led',
              lang: $route.params.lang,
            },
          }"
          class="
            block
            hover:brightness-125 hover:shadow-xl
            transition-all
            duration-300
          "
        >
          <div
            class="
              bg-cover
              rounded-lg
              min-h-[256px]
              overflow-hidden
              flex
              location-led
            "
          >
            <div
              class="
                w-full
                flex
                items-center
                justify-between
                mt-auto
                py-2.5
                px-6
                text-white
                bg-body bg-opacity-60
              "
            >
              <p>
                Великолепная<strong class="block text-base sm:text-xl mt-1">Бухара</strong>
              </p>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="w-6/12 md:w-4/12 px-2 sm:px-4 mb-4 sm:mb-8">
        <RouterLink
          :to="{
            name: 'flightDirection',
            params: {
              fromCode: 'tas',
              toCode: 'ovb',
              lang: $route.params.lang,
            },
          }"
          class="
            block
            hover:brightness-125 hover:shadow-xl
            transition-all
            duration-300
          "
        >
          <div
            class="
              bg-cover
              rounded-lg
              min-h-[256px]
              overflow-hidden
              flex
              location-ovb
            "
          >
            <div
              class="
                w-full
                flex
                items-center
                justify-between
                mt-auto
                py-2.5
                px-6
                text-white
                bg-body bg-opacity-60
              "
            >
              <p>
                Красивая<strong class="block text-base sm:text-xl mt-1">Хива</strong>
              </p>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="w-6/12 md:w-4/12 px-2 sm:px-4 mb-4 sm:mb-8">
        <RouterLink
          :to="{
            name: 'flightDirection',
            params: {
              fromCode: 'tas',
              toCode: 'ist',
              lang: $route.params.lang,
            },
          }"
          class="
            block
            hover:brightness-125 hover:shadow-xl
            transition-all
            duration-300
          "
        >
          <div
            class="
              bg-cover
              rounded-lg
              min-h-[256px]
              overflow-hidden
              flex
              location-ist
            "
          >
            <div
              class="
                w-full
                flex
                items-center
                justify-between
                mt-auto
                py-2.5
                px-6
                text-white
                bg-body bg-opacity-60
              "
            >
              <p>
                Золотой<strong class="block text-base sm:text-xl mt-1">Харезм</strong>
              </p>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="w-6/12 md:w-4/12 px-2 sm:px-4 mb-4 sm:mb-8">
        <RouterLink
          :to="{
            name: 'flightDirection',
            params: {
              fromCode: 'tas',
              toCode: 'ikt',
              lang: $route.params.lang,
            },
          }"
          class="
            block
            hover:brightness-125 hover:shadow-xl
            transition-all
            duration-300
          "
        >
          <div
            class="
              bg-cover
              rounded-lg
              min-h-[256px]
              overflow-hidden
              flex
              location-ikt
            "
          >
            <div
              class="
                w-full
                flex
                items-center
                justify-between
                mt-auto
                py-2.5
                px-6
                text-white
                bg-body bg-opacity-60
              "
            >
              <p>
                Блестящий<strong class="block text-base sm:text-xl mt-1">Ташкент</strong>
              </p>
            </div>
          </div>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<style scoped>
.location-mow {
  background-image: url("../assets/img/popular-routes/Samarqand.jpg");
}
.location-led {
  background-image: url("../assets/img/popular-routes/buxoro.jpg");
}
.location-ist {
  background-image: url("../assets/img/popular-routes/xiva.jpg");
}
.location-ikt {
  background-image: url("../assets/img/popular-routes/tashkent.jpg");
}
.location-ovb {
  background-image: url("../assets/img/popular-routes/xorazm.jpg");
}
</style>
